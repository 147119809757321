var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{attrs:{"role":"main"}},[_c('section',{staticClass:"section section-page-header"},[_c('div',{staticClass:"container m-container-only-s"},[_c('h1',[_vm._v(_vm._s(_vm.$t("upgrade-seat.info_title")))])])]),_c('section',{staticClass:"section section-default section-seats"},[_c('div',{staticClass:"container m-container-only-s"},[_c('i',{staticClass:"help-info",on:{"click":function($event){_vm.infoPopup = !_vm.infoPopup}}}),_c('div',{staticClass:"columns plane-b777-300"},[_c('div',{staticClass:"map-column"},[_c('div',{staticClass:"seats-map"},_vm._l((_vm.modifySeats.seats),function(row,index){return _c('div',{key:index,staticClass:"seats-row ",class:row.class},_vm._l((row.seats),function(seats,idx){return _c('div',{key:idx,staticClass:"seats-column",class:[
                  seats[0] &&
                    _vm.modifySeats.class[index].find(
                      function (it) { return it.group === seats[0].group; }
                    ).class,
                  { 'four-seats': seats.length === 0 && row.row === 36 }
                ]},_vm._l((seats),function(seat,ix){return _c('div',{key:ix,staticClass:"seat",class:[
                    seat.seatAvailability === 0 ||
                    seat.nameHash ||
                    seat.disabled
                      ? 'unavailable'
                      : '',
                    seat.class,
                    seat.seatNumber === _vm.parentSelected ? 'selected' : '',
                    _vm.user.seat === seat.seatNumber ? 'active' : ''
                  ],on:{"click":function($event){return _vm.checkUpgradeSeat(seat, seats)}}},[_c('i'),_c('b'),_c('div',{staticClass:"label"},[_vm._v(_vm._s(seat.seatNumber))])])}),0)}),0)}),0)]),_c('info',{attrs:{"selected-column":_vm.parentSelectedColumn,"selected":_vm.parentSelected,"type":_vm.type,"info":_vm.infoPopup},on:{"selected":function($event){_vm.parentSelected = false},"closeInfo":function($event){_vm.infoPopup = false},"update":function($event){return _vm.$emit('update', $event)}}})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }